
import { Component, OnInit, Renderer2 } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { NavigationEnd, Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { ThanhVienTokenService } from 'src/app/shared/ThanhVienToken.service';
import { DanhMucChucNangService } from 'src/app/shared/DanhMucChucNang.service';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';

@Component({
  selector: 'app-login-shared',
  templateUrl: './login-shared.component.html',
  styleUrls: ['./login-shared.component.css']
})
export class LoginSharedComponent implements OnInit {

  Token: string = environment.InitializationString;
  isLogin: boolean = true;
  MatKhauIsActive: boolean = true;
  PageTitle: string = environment.PageTitle;
  PageDescription: string = environment.PageDescription;
  queryString: string = environment.InitializationString;
  queryStringSub: string = environment.InitializationString;

  constructor(
    public router: Router,
    public ThanhVienService: ThanhVienService,
    public ThanhVienTokenService: ThanhVienTokenService,
    public NotificationService: NotificationService,
    public DanhMucChucNangService: DanhMucChucNangService,
    private renderer: Renderer2,
    private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const mainContainer = document.querySelector('.main-container');
        if (event.url === '/LoginShared') {
          this.renderer.setStyle(mainContainer, 'padding', '0');
        } else {
          this.renderer.setStyle(mainContainer, 'padding', '0 0 0 300px');
        }
      }
    });

  }

  MatKhauChangeType() {
    this.MatKhauIsActive = !this.MatKhauIsActive;
  }

  Login() {
    if (this.ThanhVienService.FormData.TaiKhoan && this.ThanhVienService.FormData.MatKhau) {
      this.LoginWithUser()
    } else {
      this.isLogin = false; // Nếu người dùng không nhập đủ thông tin
    }
  }



  loadUserData() {
    this.ThanhVienService.BaseParameter.ID = this.ThanhVienTokenService.FormData.ParentID;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.FormDataLogin = res as any;
        if (this.ThanhVienService.FormDataLogin) {
          this.saveUserDataToLocal();
          this.DanhMucChucNangGetByThanhVienIDToListAsync();
          this.router.navigate(['/BanDo007']);
        } else {
          this.isLogin = false;
          this.handleLoginFailure();
        }
      },
      err => {
        this.isLogin = false;
        this.handleLoginFailure();
      }
    );
  }

  saveUserDataToLocal() {
    localStorage.setItem(environment.ThanhVienID, this.ThanhVienService.FormDataLogin.ID.toString());
    localStorage.setItem(environment.ThanhVienParentID, this.ThanhVienService.FormDataLogin.ParentID.toString());
    localStorage.setItem(environment.ThanhVienTaiKhoan, this.ThanhVienService.FormDataLogin.TaiKhoan);
    localStorage.setItem(environment.ThanhVienHoTen, this.ThanhVienService.FormDataLogin.Name);
    localStorage.setItem(environment.ThanhVienFileName, this.ThanhVienService.FormDataLogin.FileName);
  }

  handleLoginFailure() {
    // Xử lý khi đăng nhập thất bại
    this.router.navigate(['/' + environment.LoginShared]);
  }

  DanhMucChucNangGetByThanhVienIDToListAsync() {
    if (this.queryString && this.queryString.length > 0) {
      this.queryStringSub = this.queryString.substring(1, this.queryString.length);
    }

    const Bearer = this.DanhMucChucNangService.Headers.getAll("Authorization")[0];
    if (Bearer === environment.Bearer) {
      this.DanhMucChucNangService.Headers = new HttpHeaders();
      this.DanhMucChucNangService.Headers = this.DanhMucChucNangService.Headers.append('Authorization', 'Bearer ' + this.Token);
    }

    this.DanhMucChucNangService.GetSQLByThanhVienID_ActiveToListAsync().subscribe(
      res => {
        this.DanhMucChucNangService.ListChild = (res as any[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucChucNangService.ListParent = [];
        this.isLogin = false;

        for (let i = 0; i < this.DanhMucChucNangService.ListChild.length; i++) {
          if (this.queryStringSub === this.DanhMucChucNangService.ListChild[i].Code) {
            this.DanhMucChucNangService.ListChild[i].Active = true;
          } else {
            this.DanhMucChucNangService.ListChild[i].Active = false;
          }
          if (this.queryStringSub.indexOf(this.DanhMucChucNangService.ListChild[i].Code) > -1) {
            this.isLogin = true;
          }
          this.DanhMucChucNangService.ListChild[i].Code = environment.DomainDestination + this.DanhMucChucNangService.ListChild[i].Code;
        }

        for (let i = 0; i < this.DanhMucChucNangService.ListChild.length; i++) {
          if (this.DanhMucChucNangService.ListChild[i].ParentID === 0) {
            this.DanhMucChucNangService.ListChild[i].Active = false;
            this.DanhMucChucNangService.ListChild[i].ListChild = [];

            for (let j = 0; j < this.DanhMucChucNangService.ListChild.length; j++) {
              if (this.DanhMucChucNangService.ListChild[i].ID === this.DanhMucChucNangService.ListChild[j].ParentID) {

                if (this.DanhMucChucNangService.ListChild[i].ID === 51) {
                  this.DanhMucChucNangService.ListChild[i].Name = "Tổng Hợp";
                }

                this.DanhMucChucNangService.ListChild[i].ListChild.push(this.DanhMucChucNangService.ListChild[j]);
                if (this.DanhMucChucNangService.ListChild[j].Active === true) {
                  this.DanhMucChucNangService.ListChild[i].Active = true;
                }
              }
            }

            this.DanhMucChucNangService.ListParent.push(this.DanhMucChucNangService.ListChild[i]);
          }
        }

        if (this.queryStringSub.indexOf("Homepage") > -1 || this.queryStringSub.indexOf("GioiThieu") > -1) {
          this.isLogin = true;
        }

        if (!this.isLogin) {
          this.handleLoginFailure();
        }
      },
      err => {
        this.isLogin = false;
        this.handleLoginFailure();
      }
    );
  }

  ViewMapWithoutLogin() {
    this.isLogin = true;
    this.AuthenticationToken();
  }
  AuthenticationToken() {
    debugger
    this.Token = environment.TokenDefault;
    if (!this.Token) {
      this.isLogin = false;
      this.handleLoginFailure();
    } else {
      this.ThanhVienTokenService.BaseParameter.Token = this.Token;
      this.ThanhVienTokenService.AuthenticationByTokenAsync().subscribe(
        res => {
          this.ThanhVienTokenService.FormData = res as any;
          if (this.ThanhVienTokenService.FormData && this.ThanhVienTokenService.FormData.ParentID > 0) {
            this.loadUserData();
          } else {
            this.isLogin = false;
            this.handleLoginFailure();
          }
        },
        err => {
          this.isLogin = false;
          this.handleLoginFailure();
        }
      );
    }
  }

  LoginWithUser() {
    window.location.href = `https://bandothuysan.tphcm.vungtrong.vn/#/Login`;
    // this.ThanhVienService.IsShowLoading = true;

    // const ThanhVien = {
    //   TaiKhoan: this.ThanhVienService.FormData.TaiKhoan,
    //   MatKhau: this.ThanhVienService.FormData.MatKhau,
    //   DanhMucUngDungID: this.ThanhVienService.FormData.DanhMucUngDungID || environment.DanhMucUngDungID
    // };

    // // Gọi API đăng nhập để nhận token
    // this.ThanhVienService.AuthenticationAsync().subscribe(
    //   res => {
    //     this.ThanhVienService.IsShowLoading = false;
    //     this.ThanhVienService.FormDataLogin = res as ThanhVien;

    //     if (this.ThanhVienService.FormDataLogin && this.ThanhVienService.FormDataLogin.HTMLContent) {
    //       // Lưu token vào localStorage
    //       const token = this.ThanhVienService.FormDataLogin.HTMLContent;
    //       localStorage.setItem(environment.Token, token);

    //       // Gửi tiếp một yêu cầu POST với token trong header tới API domain B
    //       const headers = new HttpHeaders({
    //         'Authorization': `Bearer ${token}`,
    //         'Content-Type': 'application/json'
    //       });
    //       const now = new Date().toISOString();

    //       // Ví dụ: lấy ngày bắt đầu là 30 ngày trước ngày hiện tại
    //       const thirtyDaysAgo = new Date();
    //       thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    //       // Chuẩn bị dữ liệu JSON
    //       const data = {
    //         SearchString: '',
    //         BatDau: thirtyDaysAgo.toISOString(),  // Ngày bắt đầu là 30 ngày trước
    //         KetThuc: now,  // Ngày kết thúc là ngày hiện tại
    //         Nam: new Date().getFullYear(),  // Năm hiện tại
    //         Thang: new Date().getMonth() + 1,  // Tháng hiện tại
    //         ID: 1
    //       };
          
    //       // Gửi yêu cầu POST với JSON body
    //       this.httpClient.post('https://api.bandothuysan.tphcm.vungtrong.vn/api/v1/ThanhVien/GetByIDAsync', JSON.stringify(data), { headers })
    //         .subscribe(
    //           (response: any) => {
    //             // Sau khi nhận phản hồi thành công, điều hướng tới domain B
                
    //           },
    //           error => {
    //             console.error('Lỗi khi gửi yêu cầu POST:', error);
    //           }
    //         );
          

    //     } else {
    //       this.NotificationService.success(environment.LoginNotSuccess);
    //     }
    //   },
    //   err => {
    //     this.NotificationService.warn(environment.LoginNotSuccess);
    //     this.ThanhVienService.IsShowLoading = false;
    //   }
    // );
  }
    
}
